<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="邀请码:">
                    <el-input class="s-input" v-model="queryParams.code" placeholder="请输入邀请码" clearable></el-input>
                </el-form-item>
                <el-form-item label="会员等级:">
                    <el-select v-model="queryParams.grade_id" placeholder="请选择会员等级" clearable @change="changeQuery">
                        <el-option v-for="(item ,index ) in  userGradeList"  :key="index" :label="item.name" v-bind:value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="不可用" :value="0"></el-option>
                        <el-option label="可用" :value="1"></el-option>
                        <el-option label="已使用" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">添加邀请码</el-button>
                <el-button type="primary" @click="adds()">添加渠道邀请码</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="id" prop="id" width="100" align="center"></el-table-column>
                <el-table-column label="订单号" prop="ordersn" width="200" align="center"></el-table-column>
                <el-table-column label="订单类型" prop="order_type_name" width="100" align="center"></el-table-column>
                <el-table-column label="拥有者" prop="uid" width="200" align="center"></el-table-column>
                <el-table-column label="有效期" prop="time_range" width="150" align="center"></el-table-column>
                <el-table-column label="会员等级" prop="ty" width="140" align="center">
                    <template slot-scope="scope">
                        <el-tag v-for="(item ,index ) in  userGradeList"  :key="index" v-if="scope.row.grade_id === item.id">{{ item.name }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 0">不可用</el-tag>
                        <el-tag type="success" v-if="scope.row.state === 1">可用</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">已使用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="ctime" width="150" align="center"></el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.state === 2" type="text" class="red"></el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 0)">
                            禁用</el-button>
                        <el-button v-if="scope.row.state === 0" type="text" class="green" @click="state(scope.row, 1)">
                            启用</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑添加邀请码 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="50%" v-if="addVisible" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" label-position="left" v-bind:model="form" v-loading.body="loading" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <el-form-item label="拥有者" prop="uid">
                    <el-input v-model="form.uid" placeholder="请输入拥有者id"></el-input>
                </el-form-item>
                <!-- <el-form-item label="渠道" v-if="form.countType === 1" prop="channel">
                    <el-input v-model="form.channel"></el-input>
                </el-form-item>-->
                <el-form-item label="数量" prop="countType">
                    <el-select v-model="form.countType" placeholder="请选择数量">
                        <el-option label="单个" v-bind:value="1"></el-option>
                        <el-option label="多个" v-bind:value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="form.countType === 2" label="个数" prop="num">
                    <el-input type="number" auto-complete="off" v-model="form.num" placeholder="请输入个数"></el-input>
                </el-form-item>
                <el-form-item label="会员等级" prop="ty">
                    <el-select v-model="form.ty" placeholder="请选择会员等级">
                        <el-option v-for="(item ,index ) in  userGradeList"  :key="index" :label="item.name" v-bind:value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="有效期" prop="time">
                    <el-input v-model="form.time" placeholder="输入时间"></el-input>
                    <span style="font-size:14px; margin-left:10px;">天</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>
        <!-- 编辑添加渠道邀请码 -->
        <el-dialog :title="title" :visible.sync="addVisibles" width="50%" v-if="addVisibles" class="dialog_box">
            <el-form class="form-box" ref="forms" v-bind:rules="rules" label-position="left" v-bind:model="forms" v-loading.body="loading" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <el-form-item label="拥有者" prop="uid">
                    <el-input v-model="forms.uid" placeholder="请输入拥有者id"></el-input>
                </el-form-item>
                <el-form-item label="会员等级" prop="ty">
                    <el-select v-model="forms.ty" placeholder="请选择会员等级">
                        <el-option v-for="(item ,index ) in  userGradeList"  :key="index" :label="item.name" v-bind:value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="激活时长（天）" prop="time">
                    <el-input v-model="forms.time_range" placeholder="输入时间"></el-input>
                    <span style="font-size:14px; margin-left:10px;">天</span>
                </el-form-item>
                <!-- <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="1">可用</el-radio>
                        <el-radio :label="0">不可用</el-radio>
                    </el-radio-group>
                </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisibles = false">取 消</el-button>
                <el-button type="primary" @click="saveForms">提 交</el-button>
            </div>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import { request } from '@/common/request'
import { deal } from '@/common/main'
import {CommonStateOk} from "@/common/const";
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            menuList: ['功能', '邀请码管理'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10,
                countType: '',
                num: '',
            },
            pageInfo: {},
            brandList: [],
            addVisible: false,
            addVisibles: false,
            title: '添加邀请码',
            rules: {
                uid: [{ message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/ }],
                countType: [{
                    required: true,
                    message: '数量不能为空',
                    trigger: 'blur'
                }],
                time_range: [{
                    required: true,
                    message: '时间不能为空',
                    trigger: 'blur'
                }, { message: '数字值不能为空', pattern: /^[0-9]*[1-9][0-9]*$/ }],
                code: [{
                    required: true,
                    message: '邀请码不能为空',
                    trigger: 'blur'
                }],
                channel: [{
                    required: true,
                    message: '渠道不能为空',
                    trigger: 'blur'
                }],
                ty: [{
                    required: true,
                    message: '等级不能为空',
                    trigger: ['blur', 'change']
                }],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },

            mode: 'add',
            form: {
            },
            forms: {},
            userGradeList:[{
                channel_id: 0,
                consumption: 0,
                customer: 0,
                id: 0,
                level: 0,
                name: "会员",
                shipping: 0,
                shop: 0,
                show_profit: false,
                state: 2,
                useprice: 0
            }]
        }
    },
    created() {
        this.loadData();
        this.loadUserGradeList();
    },
    methods: {
        //状态
        state(row, state) {
            if (state == 0) {
                this.confirmContent = '确定是否禁用?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            } else {
                this.confirmContent = '确定是否启用?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            }
        },

        loadData() {
            this.loading = true;
            //邀请码列表
            request.get('/account/memberCard/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(22222, this.pageInfo)
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.time = val.time + '天'
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadUserGradeList() {
            this.loading = true;
            //邀请码列表
            request.get('/account/grade/list', {"state":CommonStateOk}).then(ret => {
                if (ret.code === 1) {
                    this.userGradeList=[]
                    for (const grade of ret.data.list) {
                        if (grade.id >0){
                            console.log(grade)
                            this.userGradeList.push(grade)
                        }
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        add(id, index) {
            this.title = '添加邀请码'
            this.form = {}
            this.addVisible = true
        },
        adds(id, index) {
            this.title = '添加渠道邀请码'
            this.forms = {}
            this.addVisibles = true
        },

        changeCount(num) {
            this.form.num = num
        },
        changeDatePicker: function () {
            let vue = this
            if (vue.pickerDatad != null) {
                vue.form.stime = vue.pickerDatad[0]
                vue.form.etime = vue.pickerDatad[1]
            } else {
                vue.form.stime = null
                vue.form.etime = null
            }
        },
        //邀请码新增保存
        saveData() {
            console.log(this.form)
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = '/account/memberCard/add';
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success('邀请码新增成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        //渠道邀请码新增保存
        saveForms() {
            if (this.$refs.forms) {
                this.$refs.forms.validate(valid => {
                    if (valid) {
                        var url = '/account/memberCard/add';
                        request.post(url, this.forms).then(ret => {
                            if (ret.code == 1) {
                                this.addVisibles = false;
                                this.loadData();
                                this.$message.success('渠道邀请码新增成功');
                                this.$refs.forms.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/account/memberCard/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>
